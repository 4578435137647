<template>
  <div class="equipment-detail">
    <!-- 设备详情 -->
    <div class="fullh" style="background-color: #fff">
      <el-row
        type="flex"
        :gutter="20"
        justify="space-around"
        class="fullh"
        align="middle"
      >
        <el-col :span="23" class="fullh bg-purple" style="overflow: auto">
          <el-row
            type="flex"
            :gutter="20"
            class="fullh row-bg"
            justify="space-around"
          >
            <el-col :span="24">
              <div class="demo-block">
                <el-page-header @back="goBack" :content="devSlave.name">
                </el-page-header>
                <el-menu
                  :default-active="menuActive"
                  class="el-menu-demo"
                  mode="horizontal"
                >
                  <el-submenu
                    v-for="(item, i) in devSlave.list"
                    :key="i"
                    :index="String(i)"
                  >
                    <template slot="title">{{ item[0].Type }}</template>
                    <el-menu-item
                      v-for="(Dev, index) in item"
                      :key="index"
                      :index="Dev.Index"
                      @click="transfromTab(Dev.Index)"
                    >
                      <span class="deviceText">{{ Dev.Desc }}</span>
                    </el-menu-item>
                  </el-submenu>
                  <el-menu-item index="set" @click="showSetting"
                    >产品设置</el-menu-item
                  >
                </el-menu>
              </div>
              <el-tabs v-model="activeName" type="card" v-show="!setting">
                <el-tab-pane
                  label="实时告警"
                  name="0"
                  v-if="
                    DevData &&
                    DevData.Status &&
                    DevData.Status.length &&
                    DevData.Status.length != 0
                  "
                >
                  <div style="width: 1215px; margin: 0 auto">
                    <div
                      class="box-card el-card is-always-shadow"
                      v-for="(Data, i) in DevData && DevData.Status"
                      :key="i"
                    >
                      <div class="card_header">
                        <span>{{ Data.Name }}</span>
                      </div>
                      <div
                        class="card_body"
                        :class="{ alarm: Data.Value == 1 }"
                      >
                        {{ Data.AlarmDesc[Data.Value] }}
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="实时数据" name="1">
                  <div style="width: 1215px; margin: 0 auto">
                    <div
                      class="box-card el-card is-always-shadow"
                      v-for="(Data, i) in DevData && DevData.Data"
                      :key="i"
                    >
                      <div class="card_header">
                        <span>{{ Data.Name }}</span>
                      </div>
                      <div class="card_body">
                        {{ Data.Value }}{{ Data.Unit }}
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane
                  label="额定信息"
                  name="2"
                  v-if="
                    DevData &&
                    DevData.Rated &&
                    DevData.Rated.length &&
                    DevData.Rated.length != 0
                  "
                >
                  <div style="width: 1215px; margin: 0 auto">
                    <div
                      class="box-card el-card is-always-shadow"
                      v-for="(Data, i) in DevData && DevData.Rated"
                      :key="i"
                    >
                      <div class="card_header">
                        <span>{{ Data.Name }}</span>
                      </div>
                      <div class="card_body">
                        {{ Data.Value }}{{ Data.Unit }}
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="设备设置" name="3" v-if="devSettingShow">
                  <div style="width: 1215px; margin: 0 auto">
                    <div
                      class="crtl"
                      v-for="(item, index) in devCrtl"
                      :key="index"
                    >
                      <h3 class="c-title">{{ item.Name }}</h3>
                      <el-form
                        label-width="180px"
                        class="demo-ruleForm"
                        label-position="left"
                      >
                        <template
                          v-if="item.Values[0] && item.Values[0].Type === 'Num'"
                        >
                          <el-form-item
                            v-for="(item1, index1) in item.Values"
                            :key="index + '-' + index1"
                            :label="item1.Name"
                          >
                            <el-input-number
                              v-model="devCrtl[index].Values[index1].Default"
                              :min="item1.Min"
                              :max="item.Max"
                              size="small"
                            ></el-input-number>
                            {{ item1.Unit }}
                          </el-form-item>
                          <el-form-item
                            ><el-button
                              style="margin-left: 30px"
                              type="primary"
                              @click="
                                updateCrtl(index + 1, devCrtl[index].Values)
                              "
                              >提交</el-button
                            ></el-form-item
                          >
                        </template>
                        <template v-else-if="item.Values.length === 0">
                          <el-form-item
                            ><el-button
                              style="margin-left: 30px"
                              type="primary"
                              @click="updateCrtl(index + 1)"
                              >提交</el-button
                            ></el-form-item
                          >
                        </template>
                        <template v-else-if="item.Values[0].Type === 'Boolean'">
                          <el-form-item
                            v-for="(item1, index1) in item.Values"
                            :key="index + '-' + index1"
                            :label="item1.Name"
                            ><el-switch
                              v-model="devCrtl[index].Values[index1].Default"
                              :active-text="
                                devCrtl[index].Values[index1].Desc[1]
                              "
                              :inactive-text="
                                devCrtl[index].Values[index1].Desc[0]
                              "
                            ></el-switch>
                          </el-form-item>
                          <el-form-item
                            ><el-button
                              style="margin-left: 30px"
                              type="primary"
                              @click="
                                updateCrtl(index + 1, devCrtl[index].Values)
                              "
                              >提交</el-button
                            ></el-form-item
                          >
                        </template>

                        <template v-else-if="item.Values[0].Type === 'String'">
                          <el-form-item
                            v-for="(item1, index1) in item.Values"
                            :key="index + '-' + index1"
                            :label="item1.Name"
                            ><input
                              style="width: 30%; line-height: 2.25"
                              v-model="devCrtl[index].Values[index1].Default"
                            />
                          </el-form-item>
                          <el-form-item
                            ><el-button
                              style="margin-left: 30px"
                              type="primary"
                              @click="
                                updateCrtl(index + 1, devCrtl[index].Values)
                              "
                              >提交</el-button
                            ></el-form-item
                          >
                        </template>
                      </el-form>
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="通知设置" name="4">
                  <div style="width: 1215px; margin: 0 auto">
                    <div class="message">
                      <h3 class="c-title">通知管理</h3>
                      <el-button
                        size="medium"
                        type="primary"
                        @click="addUser(1)"
                        >添加人员</el-button
                      >
                      <el-dialog
                        :title="userDialogVisibleTitle"
                        :visible.sync="userDialogVisible"
                        :before-close="handleClose"
                        width="45%"
                      >
                        <el-form
                          style="width: 90%; margin: 0 auto"
                          ref="userFormRef"
                          :rules="userFormRules"
                          :model="userForm"
                          label-width="80px"
                        >
                          <el-form-item label="昵称" prop="name">
                            <el-input v-model="userForm.name"></el-input>
                          </el-form-item>
                          <el-form-item label="手机号码" prop="phone">
                            <el-input v-model="userForm.phone"></el-input>
                          </el-form-item>
                          <el-form-item label="邮箱号码" prop="email">
                            <el-input v-model="userForm.email"></el-input>
                          </el-form-item>
                          <el-form-item label="短信激活">
                            <el-switch v-model="userForm.smsActive"></el-switch>
                            <el-checkbox-group
                              v-show="userForm.smsActive"
                              v-model="userForm.smsCheckList"
                            >
                              <el-checkbox
                                v-for="(item, index) in alarmList"
                                :key="index"
                                :label="item.ParamDesc"
                              ></el-checkbox>
                            </el-checkbox-group>
                          </el-form-item>
                          <el-form-item label="电话激活">
                            <el-switch v-model="userForm.vmsActive"></el-switch>
                            <el-checkbox-group
                              v-show="userForm.vmsActive"
                              v-model="userForm.vmsCheckList"
                            >
                              <el-checkbox
                                v-for="(item, index) in alarmList"
                                :key="index"
                                :label="item.ParamDesc"
                                name="type"
                              ></el-checkbox>
                            </el-checkbox-group>
                          </el-form-item>
                          <el-form-item label="邮箱激活">
                            <el-switch
                              v-model="userForm.emailActive"
                            ></el-switch>
                            <el-checkbox-group
                              v-show="userForm.emailActive"
                              v-model="userForm.emailCheckList"
                            >
                              <el-checkbox
                                v-for="(item, index) in alarmList"
                                :key="index"
                                :label="item.ParamDesc"
                                name="type"
                              ></el-checkbox>
                            </el-checkbox-group>
                          </el-form-item>
                        </el-form>
                        <span slot="footer" class="dialog-footer">
                          <el-button @click="userDialogVisible = false"
                            >取 消</el-button
                          >
                          <el-button type="primary" @click="userUpdate()"
                            >确 定</el-button
                          >
                        </span>
                      </el-dialog>
                      <el-table :data="userList" style="width: 100%">
                        <el-table-column type="index" width="50">
                        </el-table-column>
                        <el-table-column
                          prop="UserName"
                          label="姓名"
                          width="180"
                          align="center"
                        >
                        </el-table-column>
                        <el-table-column
                          prop="SMS"
                          label="手机号"
                          width="230"
                          align="center"
                        >
                        </el-table-column>
                        <el-table-column
                          prop="Mail"
                          label="邮箱"
                          width="230"
                          align="center"
                        >
                        </el-table-column>
                        <el-table-column label="操作" align="center">
                          <template v-slot="scope">
                            <el-button
                              size="small"
                              type="primary"
                              @click="addUser(3, scope.row)"
                              >修改</el-button
                            >
                            <el-button
                              size="small"
                              type="danger"
                              @click="deleteUser(scope.row.P_Index)"
                              >删除</el-button
                            >
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
              <div
                v-show="setting"
                style="width: 1100px; margin: auto; margin-top: 80px"
              >
                <el-form label-width="100px" :model="DevSet">
                  <el-form-item label="设备名字">
                    <el-input v-model="DevSet.name">
                      <el-button
                        slot="append"
                        icon="el-icon-upload"
                        style="font-size: 32px"
                        @click="UpDataToDev(1)"
                      ></el-button>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="设备挂载节点">
                    <el-input
                      v-model="DevSet.DevSlave"
                      placeholder="串口:设备类型:设备节点:协议ID:设备描述"
                    >
                      <el-button
                        @click="UpDataToDev(2)"
                        slot="append"
                        icon="el-icon-upload"
                        style="font-size: 32px"
                      ></el-button>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="设备升级">
                    <el-select
                      v-model="DevSet.Url"
                      :placeholder="'现在程序版本->' + ShowDevinfo.versions"
                      style="width: 785px"
                    >
                      <el-option
                        v-for="(item, index) in devUpProgramList"
                        :key="index"
                        v-show="
                          ShowDevinfo.versions.indexOf(item.Filter) !== -1
                        "
                        :label="item.Name"
                        :value="item.File"
                      >
                      </el-option>
                    </el-select>
                    <el-button type="primary" @click="UpDataToDev(3)"
                      >开始升级</el-button
                    >
                    <el-button type="primary" @click="UpDataToDev(8)"
                      >重启设备</el-button
                    >
                  </el-form-item>
                  <el-form-item label="串口1调试">
                    <el-input
                      v-model="DevSet.Com1SenData"
                      placeholder="请输入发送的字符  十六进制示范：01 03 00 01 00 01 FF FF"
                    >
                      <el-select
                        v-model="DevSet.Com1DbugBaud"
                        slot="prepend"
                        placeholder="点击选择波特率"
                        style="width: 150px; margin: -10px 0px"
                      >
                        <el-option label="2400" value="2400"></el-option>
                        <el-option label="4800" value="4800"></el-option>
                        <el-option label="9600" value="9600"></el-option>
                        <el-option label="14400" value="14400"></el-option>
                        <el-option label="19200" value="19200"></el-option>
                        <el-option label="115200" value="115200"></el-option>
                      </el-select>
                      <el-select
                        v-model="DevSet.Com1DataType"
                        slot="prepend"
                        placeholder="点击选择输入类型"
                        style="width: 170px; margin: -10px 0px"
                      >
                        <el-option label="字符串" value="1"></el-option>
                        <el-option label="十六进制" value="2"></el-option>
                      </el-select>
                      <el-button
                        slot="append"
                        icon="el-icon-upload"
                        style="font-size: 32px"
                        @click="UpDataToDev(4)"
                      ></el-button>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="串口2调试">
                    <el-input
                      v-model="DevSet.Com2SenData"
                      placeholder="请输入发送的字符  十六进制示范：01 03 00 01 00 01 FF FF"
                    >
                      <el-select
                        v-model="DevSet.Com2DbugBaud"
                        slot="prepend"
                        placeholder="点击选择波特率"
                        style="width: 150px; margin: -10px 0px"
                      >
                        <el-option label="2400" value="2400"></el-option>
                        <el-option label="4800" value="4800"></el-option>
                        <el-option label="9600" value="9600"></el-option>
                        <el-option label="14400" value="14400"></el-option>
                        <el-option label="115200" value="115200"></el-option>
                      </el-select>
                      <el-select
                        v-model="DevSet.Com2DataType"
                        slot="prepend"
                        placeholder="点击选择输入类型"
                        style="width: 170px; margin: -10px 0px"
                      >
                        <el-option label="字符串" value="1"></el-option>
                        <el-option label="十六进制" value="2"></el-option>
                      </el-select>
                      <el-button
                        slot="append"
                        icon="el-icon-upload"
                        style="font-size: 32px"
                        @click="UpDataToDev(5)"
                      ></el-button>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="串口1波特率">
                    <el-select
                      v-model="DevSet.Com1Baud"
                      placeholder="点击选择波特率"
                      style="width: 150px; margin: -10px 0px"
                    >
                      <el-option label="2400" value="2400"></el-option>
                      <el-option label="4800" value="4800"></el-option>
                      <el-option label="9600" value="9600"></el-option>
                      <el-option label="14400" value="14400"></el-option>
                      <el-option label="19200" value="19200"></el-option>
                    </el-select>
                    <el-button type="warning" @click="UpDataToDev(6)"
                      >下发设置</el-button
                    >
                  </el-form-item>
                  <el-form-item label="串口2波特率">
                    <el-select
                      v-model="DevSet.Com2Baud"
                      placeholder="点击选择波特率"
                      style="width: 150px; margin: -10px 0px"
                    >
                      <el-option label="2400" value="2400"></el-option>
                      <el-option label="4800" value="4800"></el-option>
                      <el-option label="9600" value="9600"></el-option>
                      <el-option label="14400" value="14400"></el-option>
                      <el-option label="19200" value="19200"></el-option>
                    </el-select>
                    <el-button type="warning" @click="UpDataToDev(7)"
                      >下发设置</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
var Qs = require("qs");
import {
  getDevData,
  _getDevCtrl,
  _setDevCtrl,
  _getDevUpProgram,
  _getNotification,
  _setUserNotification,
  _deleteUserNotification,
} from "../../api/require";
export default {
  data() {
    return {
      //   查看
      ShowDev: 0,
      ShowDevinfo: {
        versions: this.$route.query.versions,
        Index: this.$route.params.index,
      },
      // 详情页
      activeName: "1",
      menuActive: this.$route.query.index || "1", //顶部选中导航
      DevData: {},
      timerID: 0, //定时器
      //from表单
      setting: false,
      DevSet: {},
      devType: "",
      id: this.$route.params.index,
      devSlave: "",
      devCrtl: [],
      devSettingShow: false,
      devUpProgramList: [],
      userDialogVisible: false, //添加用户对话框
      userDialogVisibleTitle: "",
      userForm: {
        //添加人员
        type: 0,
        name: "",
        phone: "",
        email: "",
        smsActive: false,
        vmsActive: false,
        emailActive: false,
        smsCheckList: [],
        vmsCheckList: [],
        emailCheckList: [],
      },
      alarmList: [], //告警选择
      userList: [],
      alarmDescList: [], //告警描述
      userFormRules: {
        //表单检验
        name: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        phone: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur",
          },
          {
            required: true,
            pattern: /^1[34578]\d{9}$/,
            message: "请输入正确的手机号",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入邮箱地址",
            trigger: "blur",
          },
          {
            required: true,
            pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            message: "请输入正确的邮箱地址",
          },
        ],
      },
    };
  },
  created() {
    this.getSlaveList(this.$route.params.index);
    this.ShowDevData(this.$route.params.index, Number(this.menuActive));
    this.getDevUpProgram();
    // this.getUserList();
  },
  beforeDestroy() {
    this.goBack();
  },
  methods: {
    async getSlaveList(index) {
      const { data: res } = await axios.get("/API/DevSlaveList.php", {
        params: {
          Index: index,
        },
      });
      this.devSlave = res;
      this.getDevCtrl();
    },
    // 加载UPS数据
    async ShowDevData(id, index) {
      const res = await getDevData(id, index);
      this.DevData = res.data;
      setTimeout(() => {
        this.judgeActive();
      }, 10);
      this.$message({
        message: "信息加载成功",
        type: "success",
      });
      this.timer(index);
    },
    //定时器
    timer(index) {
      clearInterval(this.timerID);
      this.timerID = setInterval(async () => {
        const res = await getDevData(this.id, index);
        this.DevData = res.data;
      }, 5000);
    },
    //获取设备设置升级列表
    async getDevUpProgram() {
      const { data: res } = await _getDevUpProgram();
      this.devUpProgramList = res;
    },
    //获取设备用户列表
    async getUserList() {
      // console.log("设备dtype" + this.devType);
      const { data: res } = await _getNotification(
        0,
        this.id,
        this.devType,
        Number(this.devType)
      );
      this.userList = res.List;
      this.alarmList = res.AlarmList;
      this.alarmDescList = this.alarmList.map((el) => el.ParamDesc);
    },
    //添加修改用户
    addUser(index, row) {
      if (index === 1) {
        this.userDialogVisibleTitle = "添加用户";
        //添加人员
        this.userForm = {
          type: 1,
          name: "",
          phone: "",
          email: "",
          smsActive: false,
          vmsActive: false,
          emailActive: false,
          smsCheckList: [],
          vmsCheckList: [],
          emailCheckList: [],
        };
        if (this.alarmDescList)
          this.alarmList.map((el) => {
            this.userForm.smsCheckList.push(el.ParamDesc);
            this.userForm.vmsCheckList.push(el.ParamDesc);
            this.userForm.emailCheckList.push(el.ParamDesc);
          });
      } else if (index === 3) {
        this.userDialogVisibleTitle = "修改用户";
        this.userForm = {
          type: 3,
          id: row.P_Index,
          name: row.UserName,
          phone: row.SMS,
          email: row.Mail,
          smsActive: Boolean(Number(row.SMSAlarm)),
          vmsActive: Boolean(Number(row.VSMSAlarm)),
          emailActive: Boolean(Number(row.MailAlarm)),
          smsCheckList: [],
          vmsCheckList: [],
          emailCheckList: [],
        };
        if (this.alarmDescList)
          this.alarmList.map((el) => {
            if (row.SMSAlarmMask[el.DataID] == 1) {
              this.userForm.smsCheckList.push(el.ParamDesc);
            }
            if (row.VMSAlarmMask[el.DataID] == 1) {
              this.userForm.vmsCheckList.push(el.ParamDesc);
            }
            if (row.MailAlarmMask[el.DataID] == 1) {
              this.userForm.emailCheckList.push(el.ParamDesc);
            }
          });
      }

      this.userDialogVisible = true;
    },
    //删除用户
    deleteUser(id) {
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data: res } = await _deleteUserNotification(2, id);
          if (res.return) {
            this.getUserList();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          } else {
            this.$message({
              type: "error",
              message: "删除失败!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //用户提交
    async userUpdate() {
      this.$refs["userFormRef"].validate(async (valid) => {
        if (valid) {
          let smsList = [];
          let vmsList = [];
          let emailList = [];

          this.alarmDescList.forEach((el, index) => {
            if (this.userForm.smsCheckList.length !== 0) {
              this.userForm.smsCheckList.forEach((el1) => {
                if (el == el1) {
                  smsList.push(this.alarmList[index].DataID);
                }
              });
            }
            if (this.userForm.vmsCheckList.length !== 0) {
              this.userForm.vmsCheckList.forEach((el1) => {
                if (el == el1) {
                  vmsList.push(this.alarmList[index].DataID);
                }
              });
            }
            if (this.userForm.emailCheckList.length !== 0) {
              this.userForm.emailCheckList.forEach((el1) => {
                if (el == el1) {
                  emailList.push(this.alarmList[index].DataID);
                }
              });
            }
          });
          const { data: res } = await _setUserNotification(
            this.userForm.type, //请求类型1添加 3修改
            this.id, //设备id
            Number(this.devType), //选中的设备类型
            this.userForm.name,
            this.userForm.phone,
            this.userForm.email,
            Number(this.userForm.smsActive),
            Number(this.userForm.vmsActive),
            Number(this.userForm.emailActive),
            JSON.stringify(smsList),
            JSON.stringify(vmsList),
            JSON.stringify(emailList),
            this.userForm.id //用户id 用于修改
          );
          if (res.return) {
            this.$message({
              message: "执行成功",
              type: "success",
            });
            this.getUserList();
            this.userDialogVisible = false;
          }
        }
      });
    },
    // 表单提交
    UpDataToDev(Index) {
      var Updata = this.DevSet;
      Updata.Index = Index;
      Updata.DevIndex = this.id;
      this.$confirm("不正确的操作可能导致设备异常!!!", "是否执行点击的操作?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          axios({
            method: "post",
            url: "/API/UpDataToDev.php",
            data: Updata,
            transformRequest: [
              (data) => {
                return Qs.stringify(data); //使用Qs将请求参数序列化
                // return data
              },
            ],
            headers: {
              "Content-Type": "application/x-www-form-urlencoded", //必须设置传输方式
            },
          }).then((res) => {
            if (res.data.Code) {
              this.openFullScreen();
            } else {
              this.$message({
                message: res.data.Msg,
                type: "warning",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    GetUpDataState() {
      axios({
        method: "post",
        url: "/API/GetUpDataState.php",
        data: this.ShowDevinfo,
        transformRequest: [
          (data) => {
            return Qs.stringify(data); //使用Qs将请求参数序列化
          },
        ],
        headers: {
          "Content-Type": "application/x-www-form-urlencoded", //必须设置传输方式
        },
      }).then((res) => {
        console.log("res=>", res);
        if (res.data.Msg.DevControlStatus == 1) {
          this.loading.close();
          this.$message({
            message: "指令已送达",
            type: "success",
          });
        } else if (res.data.Msg.DevControlStatus == 3) {
          this.loading.close();
          this.$message({
            message: "指令超时,设备通讯异常",
            type: "error",
          });
        } else {
          setTimeout(() => {
            this.GetUpDataState();
          }, 1000);
        }
      });
    },
    //获取设备设置
    async getDevCtrl() {
      let type = "";
      this.devSlave.list.forEach((el) => {
        if (typeof el !== "undefined")
          el.forEach((el1) => {
            if (el1.Index == this.menuActive) {
              type = el1.Type;
              this.devType = el1.TypeNum;
            }
          });
      });
      this.getUserList();
      const { data: res } = await _getDevCtrl(type);
      this.devCrtl = res;
      if (res) {
        this.devSettingShow = true;
      } else {
        this.devSettingShow = false;
      }
    },
    async updateCrtl(index, list) {
      const _list = list.map((item) => item.Default);
      const { data: res } = await _setDevCtrl(
        Number(this.id),
        Number(this.menuActive),
        index,
        _list
      );
      this.loading = this.$loading({
        lock: true,
        text: "等待指令执行中....",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.GetUpDataState();
      console.log(res);
      console.log(this.id, Number(this.menuActive), index, list);
    },
    openFullScreen() {
      this.loading = this.$loading({
        lock: true,
        text: "等待指令执行中....",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.GetUpDataState();
    },
    /* eslint-disable */
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    /* eslint-enable */
    goBack() {
      //退出前清除定时器
      clearInterval(this.timerID);
      this.menuActive = "1";
      this.$router.push("/group-management");
      // this.$router.push(-1);
    },

    showSetting() {
      clearInterval(this.timerID);
      this.setting = true;
    },
    transfromTab(index) {
      this.ShowDevData(this.id, index);
      this.menuActive = index;
      this.setting = false;
      this.getDevCtrl();
      this.getUserList();
    },
    judgeActive() {
      //
      if (this.DevData.Status && this.DevData.Status.length != 0) {
        this.activeName = "0";
      } else {
        this.activeName = "1";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.equipment-detail {
  width: 100%;
  height: 100%;
}
.fullh {
  height: 100%;
}
.el-page-header {
  float: left;
  padding: 18px;
}
.el-menu.el-menu--horizontal {
  float: right;
  background-color: #f9fafc;
}
.demo-block {
  border: 1px solid #ebebeb;
  border-radius: 3px;
  transition: 0.2s;
  height: 59px;
}
.card_body {
  padding: 20px;
  background: #67c23a;
  color: white;
  line-height: 20px;
}
.alarm {
  background: #f56c6c;
}
.card_header {
  padding: 18px 20px;
  border-bottom: 1px solid #ebeef5;
  box-sizing: border-box;
}

.box-card {
  width: 220px;
  float: left;
  text-align: center;
  margin: 10px;
}
.c-title {
  padding-left: 10px;
  border-left: 5px solid skyblue;
  font-size: 17px;
  font-weight: bold;
  color: rgb(73, 71, 71);
  margin: 20px 0px;
}
</style>